<script>
import VxQuery from "@/components/vx/VxQuery";
import MessageThreadDetailListItem from "./MessageThreadListDetailListItem.vue";

import { formatDistanceToNow, parseISO, format } from "date-fns";
import { materialAbsoluteDateTimeString } from "@/utils/datetime";
import { formatNumber } from "@/utils/phonenumber";
import { last, partition, orderBy, get } from "lodash";
import { hasCurrentStoreAccess } from "@/mixins/store";

export default {
  name: "MessageThreadListDetail",
  components: {
    MessageThreadDetailListItem,
    VxQuery,
  },
  mixins: [hasCurrentStoreAccess],
  props: {
    conversation: {
      type: Boolean,
      default: true,
    },
    search: {
      type: String,
      default: null,
    },
  },
  data: () => ({}),
  computed: {
    currentStoreId() {
      return this.currentStore?.id.toString();
    },
    noThreadsMessage() {
      return `No ${this.conversation ? "conversations" : "message threads"}`;
    },
    variables() {
      return {
        storeId: this.currentStore.id,
        input: { conversation: this.conversation },
      };
    },
    routeId() {
      return last(this.$route.path.split("/"));
    },
  },
  methods: {
    formatNumber,
    materialAbsoluteDateTimeString,
    initials(name) {
      if (name === null || name === undefined) return "";
      return name
        .split(" ")
        .map((e) => e.charAt(0))
        .join("")
        .toUpperCase();
    },
    dateInWords(date) {
      return formatDistanceToNow(parseISO(date));
    },
    dateFormatted(date) {
      return format(parseISO(date), "M/d/yyyy @ h:mm aaa");
    },
    isActive(threadId) {
      return this.routeId === threadId;
    },
    unreadFirst(threads = []) {
      // Put `lastMessageAt` on each thread for easy reduction
      threads = threads.map((e) => ({
        ...e,
        lastMessageAt: get(e, "lastMessage.createdAt"),
      }));

      // Put any threads without `lastMessageAt` at the bottom
      // Then, order each set by `unread, lastMessageAt`
      // Finally, concat the array of threads without `lastMessageAt`
      return partition(threads, (e) => !!e.lastMessageAt).reduce(
        (result, current) =>
          result.concat(
            orderBy(current, ["unread", "lastMessageAt"], ["desc", "desc"])
          ),
        []
      );
    },
  },
};
</script>

<template>
  <VxQuery
    :query="require('@/views/Texting/AppView/graphql').TEXTING_VIEW_LIST"
    :variables="{
      storeId: currentStoreId,
      input: { search: search },
    }"
    :skip="!currentStoreId"
    :debounce="400"
    fetch-policy="cache-and-network"
  >
    <template #loading>
      <v-list two-line class="pt-0 ui-l4 rai-text-threads rai-scrollbar">
        <v-skeleton-loader
          type="list-item-avatar-two-line@5"
        ></v-skeleton-loader>
      </v-list>
    </template>
    <template #error row justify-center fill-height mt-4>
      <v-card flat color="grey--text">
        <v-card-title class="justify-center">
          <v-icon
            color="grey lighten-1"
            v-text="'$vuetify.icons.warning'"
          ></v-icon>
        </v-card-title>
        <v-card-title class="text-h6"
          >An error occurred while fetching message threads</v-card-title
        >
        <v-card-text>
          <p class="text-body-1">
            {{ error.graphQLErrors.map((e) => e.message).join("\n") }}
          </p>
        </v-card-text>
      </v-card>
    </template>

    <template v-slot:data="{ data: { messageThreads } }">
      <template v-if="messageThreads && messageThreads.length > 0">
        <v-list two-line class="pt-0 ui-l4 rai-text-threads rai-scrollbar">
          <message-thread-detail-list-item
            v-for="thread in unreadFirst(messageThreads)"
            :key="thread.id"
            :thread="thread"
          />
        </v-list>
      </template>

      <template v-else row>
        <v-card flat color="grey--text">
          <v-card-title class="text-h6" v-text="noThreadsMessage" />
        </v-card>
      </template>
    </template>
  </VxQuery>
</template>
