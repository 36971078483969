<template>
  <v-fade-transition>
    <v-alert outlined type="error" class="vx-query--error">
      <slot />
    </v-alert>
  </v-fade-transition>
</template>

<script>
export default {
  name: "VxQueryError",
};
</script>
