<template>
  <v-btn
    dark
    fixed
    fab
    bottom
    right
    color="accent"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-icon>$vuetify.icons.add</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "MessageNewButtonMobile",
  inheritAttr: false,
};
</script>
