import MessageNewButtonMobile from "./MessageNewButtonMobile";
import MessageNewButtonDesktop from "./MessageNewButtonDesktop";

export default {
  name: "MessageNewButton",
  functional: true,
  inheritAttrs: false,
  render(h, context) {
    const responsiveComponent = context.parent.$vuetify.breakpoint.xsOnly
      ? MessageNewButtonMobile
      : MessageNewButtonDesktop;

    return h(
      responsiveComponent,
      {
        props: context.props,
        ...context.data,
        scopedSlots: context.scopedSlots,
      },
      context.$slots.default
    );
  },
};
