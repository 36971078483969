export const formatNumber = (phoneNumber) => {
  if (!phoneNumber) return phoneNumber;
  const regex = /^(?:\+1)?(\d{3})(\d{3})(\d{4})$/;
  phoneNumber = phoneNumber.trim();
  const results = phoneNumber.match(regex);

  // If no match, return the original string
  if (!results) return phoneNumber;

  // If matches exist, destructure them
  let [, a, b, c] = results;

  // If all groups exist, return the formatted string
  if (a && b && c) return `(${a}) ${b}-${c}`;

  // Otherwise, return the original string
  return phoneNumber;
};

export const removeAreaCode = (phoneNumber) => {
  if (!phoneNumber) return phoneNumber;
  const regex = /^\+?1?(\d{3})(\d{3})(\d{4})$/;
  phoneNumber = phoneNumber.trim();
  const results = phoneNumber.match(regex);

  // If no match, return the original string
  if (!results) return phoneNumber;

  // If matches exist, destructure them
  const num = results.slice(1);

  // If all groups exist, return the formatted string
  if (num.length === 3) return num.join("");

  // Otherwise, return the original string
  return phoneNumber;
};

export const normalize = (phoneNumber) => {
  // Guard
  if (!phoneNumber || typeof phoneNumber !== "string") return phoneNumber;

  const correctFormat = /^\+1\d+$/;

  // If phoneNumber is already formatted, return it
  if (correctFormat.test(phoneNumber)) return phoneNumber;

  // Remove non-numbers
  phoneNumber = phoneNumber.replace(/[^\d]/, "");

  // Add the area code if it's not there
  if (phoneNumber.charAt(0) !== "1") {
    phoneNumber = `1${phoneNumber}`;
  }

  // Add the plus
  phoneNumber = `+${phoneNumber}`;

  return phoneNumber;
};
