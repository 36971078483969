<template>
  <v-fade-transition>
    <v-simple-table class="vx-query--data">
      <slot />
    </v-simple-table>
  </v-fade-transition>
</template>

<script>
export default {
  name: "VxQueryData",
};
</script>
