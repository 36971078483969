import { STORE } from "@/graphql/store/queries.gql";
import { hasRouteStore } from "@/mixins/routerParams";

export const hasCurrentStoreAccess = {
  mixins: [hasRouteStore],
  apollo: {
    currentStore: {
      query: STORE,
      variables() {
        return {
          id: this.storeId,
        };
      },
      update(data) {
        return data.store;
      },
      skip() {
        return !this.storeId;
      },
    },
  },
};

// used by buys/DialogCustomerDetail
export const hasBuyConfigAccess = {
  apollo: {
    buyConfig: {
      query: STORE,
      variables() {
        return {
          id: this.storeId,
        };
      },
      update({ store: { buyConfig } }) {
        return buyConfig;
      },
      skip() {
        return !this.storeId;
      },
    },
  },
};
