<template>
  <v-fade-transition>
    <v-container fluid class="fill-height vx-empty-state">
      <v-row
        class="fill-height text--secondary"
        align="center"
        align-content="center"
        justify="space-around"
      >
        <slot />
      </v-row>
    </v-container>
  </v-fade-transition>
</template>

<script>
export default {
  name: "VxEmptyState",
};
</script>
