import { startOfToday, startOfTomorrow, format, parseISO } from "date-fns";
import { globalCurrentTime } from "@/mixins/time";

const setQueryParam = (vm, key, val) => {
  if (val === null || val === "") {
    vm.$router.replace({ query: {} });
    return;
  }
  const query = { ...vm.$route.query, [key]: val };
  // Prevents a route push if the current query already includes the given query
  if (key in vm.$route.query && val === vm.$route.query[key]) return true;

  vm.$router.replace({ query });
};

export const useNamespace = (namespace) => ({
  methods: {
    i18n(key, ...replace) {
      const val = this.$t(`${namespace}.${key}`);
      if (!val) return "";

      if (!replace.length) return val;

      return replace.reduce((str, replacement) => {
        return str.replace(/<#.*?#>/i, replacement);
      }, val);
    },
  },
});

export const hasDefaultTab = (value) => ({
  created() {
    this.tab = this.tab || value;
  },
});

export const hasRouteTab = {
  computed: {
    tab: {
      get() {
        return this.$route.query.t;
      },
      set(val) {
        setQueryParam(this, "t", val);
      },
    },
  },
};

export const hasRouteSearch = {
  computed: {
    search: {
      get() {
        return this.$route.query.q;
      },
      set(val) {
        setQueryParam(this, "q", val);
      },
    },
    advancedSearchObject: {
      get() {
        let queryToParse = this.$route.query;

        if (this.search == undefined && Object.keys(queryToParse).length > 0) {
          return { ...queryToParse };
        }

        return null;
      },
      set(vals) {
        for (var key in vals) {
          setQueryParam(this, key, vals[key]);
        }
      },
    },
  },
};

export const hasRoutePage = {
  computed: {
    page: {
      get() {
        return this.$route.query.p || 1;
      },
      set(val) {
        setQueryParam(this, "p", val);
      },
    },
  },
};

export const hasRouteDay = {
  /*
   * This causes weird behavior. It is better to always have d set in the query params
  watch: {
    CURRENT_DAY(currentDay) {
      if (this.$route.query.d === currentDay) {
        setQueryParam(this, "d", null);
      }
    },
  },
  */
  mixins: [globalCurrentTime],
  methods: {
    // When a view requires a day (aka Daybook), force a default
    defaultToToday() {
      if (!!!this.$route.query.d) {
        setQueryParam(this, "d", this.CURRENT_DAY);
      }
    },
  },
  computed: {
    day: {
      get() {
        return this.$route.query.d ? this.$route.query.d : this.CURRENT_DAY;
      },
      set(val) {
        setQueryParam(this, "d", val);
      },
    },
    dayDate: {
      // formatted to ensure local time zone
      get() {
        return parseISO(this.day);
        //return new Date(`${this.day}T00:00:00`);
      },
      set(val) {
        // this ignores timezone
        this.day =
          typeof val === "string"
            ? format(parseISO(val), "yyyy-MM-dd")
            : format(val, "yyyy-MM-dd");
      },
    },
    dayIsPast() {
      return this.dayDate < startOfToday();
    },
    dayIsFuture() {
      return this.dayDate >= startOfTomorrow();
    },
    dayIsToday() {
      return this.day === this.CURRENT_DAY;
    },
  },
};

export const hasRouteStore = {
  computed: {
    storeId: {
      get() {
        return this.$route.params.storeId;
      },
      set(val) {
        this.$router.push({ params: { storeId: val } });
      },
    },
  },
};

export const hasChildDialog = {
  methods: {
    dialogRoute(route) {
      return {
        ...route,
        params: {
          ...this.$route.params,
          ...route.params,
        },
        query: {
          ...this.$route.query,
          ...route.query,
        },
      };
    },
  },
};
