<template>
  <v-fade-transition>
    <v-container fluid class="fill-height vx-query--loading">
      <v-row
        class="fill-height"
        align="center"
        align-content="center"
        justify="space-around"
      >
        <slot>
          <v-progress-circular
            indeterminate
            size="48"
            width="2"
            color="accent"
            class="ma-auto"
          />
        </slot>
      </v-row>
    </v-container>
  </v-fade-transition>
</template>

<script>
export default {
  name: "VxQueryLoading",
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: "accent",
    },
    size: {
      type: [Number, String],
      default: 48,
    },
    width: {
      type: [Number, String],
      default: 1,
    },
  },
};
</script>
