<template>
  <!-- there is no wrapper tag, so data MUST have only one top-level item -->
  <ApolloQuery :tag="tag" v-bind="$attrs" v-on="$listeners">
    <slot name="subscriptions" />
    <template v-slot="{ result: { loading, error, data }, isLoading, query }">
      <template v-if="isLoading">
        <slot name="loading" :loading="loading">
          <VxQueryLoading :loading="loading" />
        </slot>
      </template>
      <template v-else-if="error">
        <slot name="error" :error="error">
          <VxQueryError>{{ error }}</VxQueryError>
        </slot>
      </template>
      <template v-else>
        <slot name="completed">
          <!-- query has completed and has data and the first object key has a length > 0 -->
          <!-- TODO: need a way to determine empty results -->
          <template
            v-if="
              data &&
              (data[Object.keys(data)[0]].length > 0 ||
                isObj(data[Object.keys(data)[0]]))
            "
          >
            <slot name="data" :data="data" :query="query">
              <VxQueryData>
                <slot name="dataRows" />
              </VxQueryData>
            </slot>
          </template>
          <template v-else>
            <slot name="empty">
              <VxEmptyState>
                <slot name="emptyContent" />
              </VxEmptyState>
            </slot>
          </template>
        </slot>
      </template>
    </template>
  </ApolloQuery>
</template>

<script>
import VxQueryLoading from "./VxQueryLoading";
import VxQueryError from "./VxQueryError";
import VxEmptyState from "../VxEmptyState";
import VxQueryData from "./VxQueryData";

export default {
  components: {
    VxQueryLoading,
    VxQueryError,
    VxQueryData,
    VxEmptyState,
  },
  inheritAttrs: false,
  props: {
    // allows adding a wrapper tag to have multiple top-level elements
    // warning: add fill-height class so that loading and empty positioning works
    tag: {
      type: String,
      default: null,
    },
  },
  methods: {
    isObj(val) {
      return typeof val === "object";
    },
  },
};
</script>
